
export const Portfolios = [
    {
        image:require("../assets/portfolio/p1.jpeg"),
    },
    {
        image:require("../assets/portfolio/p2.jpeg"),
    },{
        image:require("../assets/portfolio/p3.jpeg"),
    },
    {
        image:require("../assets/portfolio/p4.jpeg"),
    },
    {
        image:require("../assets/portfolio/p5.jpeg"),
    },
    {
        image:require("../assets/portfolio/p6.jpeg"),
    },
    {
        image:require("../assets/portfolio/p7.jpeg"),
    },
    {
        image:require("../assets/portfolio/p8.jpeg"),
    },
    {
        image:require("../assets/portfolio/p10.jpeg"),
    },
    {
        image:require("../assets/portfolio/p11.jpeg"),
    },
    {
        image:require("../assets/portfolio/p12.jpeg"),
    },
    {
        image:require("../assets/portfolio/p13.jpeg"),
    },
    {
        image:require("../assets/portfolio/p14.jpeg"),
    },
    {
        image:require("../assets/portfolio/p14.jpeg"),
    },
    {
        image:require("../assets/portfolio/p16.jpeg"),
    },
    {
        image:require("../assets/portfolio/p17.jpeg"),
    },
    {
        image:require("../assets/portfolio/p18.jpeg"),
    },
    {
        image:require("../assets/portfolio/p19.jpeg"),
    },
    {
        image:require("../assets/portfolio/p20.jpeg"),
    },
    {
        image:require("../assets/portfolio/p21.jpeg"),
    },
    
  ];
  