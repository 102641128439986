
export const Clients = [
  {
    id: "cl15",
    name: "TTk healthcare",
    image:require("../assets/clients/client-10.png"),
    link: "https://ttkhealthcare.com/",
  },
  {
    id: "cl16",
    name: "IIT chennie",
    image:require("../assets/clients/client-16.jpeg"),
    link: "https://www.iitm.ac.in/",
  },
  {
    id: "cl1",
    name: "Nalco",
    image:require("../assets/clients/client-14.png"),
    link: "https://nalcoindia.com/",
  },
  {
    id: "cl2",
    name: "Bellowseal",
    image:require("../assets/clients/client-15.png"),
    link: "https://bellowseal.com/",
  },
  {
    id: "cl3",
    name: "Krishnaveni Carbon",
    image:require("../assets/clients/client-13.png"),
    link: "https://www.krishcarbon.com/",
  },{
    id: "cl4",
    name: "stanadyne",
    image:require("../assets/clients/client-12.png"),
    link: "https://www.stanadyne.com/",
  },
  {
    id: "cl5",
    name: "Titan industries",
    image:require("../assets/clients/client-11.png"),
    link: "https://www.titancompany.in/",
  },
  {
    id: "cl6",
    name: "Micheal bearings ",
    image:require("../assets/clients/client-1.png"),
    link: "https://www.michellbearings.com/",
  },
  
  {
    id:"cl7",
    name: "Schunk carbon",
    image:require("../assets/clients/client-2.png"),
    link: "https://www.schunk-group.com/",
  },
  {
    id: "cl8",
    name: "British engine",
    image:require("../assets/clients/client-3.png"),
    link: "https://www.britishengines.co.uk/",
  },
  {
    id: "cl9",
    name: "Flowserve Sanmar",
    image:require("../assets/clients/client-4.png"),
    link: "https://www.flowserve.com/en/",
  },
  {
    id: "cl10",
    name: "Rane TRW",
    image:require("../assets/clients/client-5.png"),
    link: "https://www.crunchbase.com/organization/rane-trw-steering-systems",
  },
  {
    id: "cl11",
    name: "Delphi TVS",
    image:require("../assets/clients/client-6.png"),
    link: "https://sis.delphitvs.com/",
  },
  {
    id: "cl12",
    name: "Gilbarco Veeder-Root",
    image:require("../assets/clients/client-7.png"),
    link: "https://www.gilbarco.com/",
  },
  {
    id: "cl13",
    name: "Trelleborg sealing solutions",
    image:require("../assets/clients/client-8.png"),
    link: "https://www.trelleborg.com/",
  },
  {
    id: "cl14",
    name: "Poclain hydraulics",
    image:require("../assets/clients/client-9.png"),
    link: "https://poclain-hydraulics.com/",
  },
  
];
