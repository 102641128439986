import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router";
import keywordExtractor from "keyword-extractor"; // Importing keyword extraction library

function ProductCard({ img, app, info, descrip, images }) {
  const navigate = useNavigate();

  // Function to extract keywords from description
  const extractKeywords = (description) => {
    const keywords = keywordExtractor.extract(description, {
      language: "english",
      remove_digits: true,
      return_changed_case: true,
      remove_duplicates: true,
    });
    return keywords.slice(0, 10).join(", "); // Convert array of keywords to comma-separated string
  };

  const keywords = extractKeywords(descrip); // Extract keywords from description

  const handleClick = () => {
    navigate("/productDetail", {
      state: {
        information: { info },
        description: { descrip },
        photos: { images },
      },
    });
  };

  return (
    <div
      className="fit-content product-item filter-app"
      data-aos="zoom-in"
      data-aos-delay="200"
    >
      <Card
        className="card pe-auto"
        sx={{
          maxWidth: 600,
          borderRadius: "10px",
          transition: "box-shadow 0.3s ease, transform 0.3s ease", // Add transition for smooth effect
          "&:hover": {
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)", // Add box shadow on hover
            transform: "scale(1.05)", // Add zoom effect on hover
          },
        }}
      >
        <div
          style={{
            position: "relative",
            borderRadius: "10px",
            marginTop: "-10px",
            cursor: "pointer",
          }}
          className="img-fluid"
          onClick={() => handleClick()}
        >
          <CardMedia
            component="img"
            height="194"
            image={img}
            alt="dish image"
          />
        </div>

        <CardContent style={{ textAlign: "left" }}>
          <Typography variant="h6" component="div">
            {app}
          </Typography>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>{info.size}</div>
            <Typography
              variant="body1"
              component="div"
              color="text.secondary"
            >
              {info.Category}
            </Typography>
          </div>

          <Typography
            variant="body2"
            component="div"
            color="text.secondary"
          >
            {keywords}{" "}
            {/* Display extracted keywords */}
          </Typography>
        </CardContent>
        <CardActions
          disableSpacing
          display={"flex"}
          style={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            gutterBottom
            display={"flex"}
            alignItems={"center"}
          >
            <a onClick={() => handleClick()} title="More Details">
              {" "}
              <span
                style={{
                  fontSize: "20px",
                  marginBottom: "20px",
                  cursor: "pointer",
                }}
              >
                Details
              </span>{" "}
              <i style={{ marginTop: "10px" }} className="bi bi-link"></i>
            </a>
          </Typography>
        </CardActions>
      </Card>
    </div>
  );
}

export default ProductCard;
