import React, { useEffect } from "react";
import "./about.css";
import "bootstrap/dist/css/bootstrap.min.css";
import about from "../../assets/images/about.jpg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Mousewheel, Autoplay } from "swiper";
import { Clients } from "../../Data/Clients";
import { Portfolios } from "../../Data/Portfolios";
import useMediaQuery from "@mui/material/useMediaQuery";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

function About() {
  const data = Clients;

  const isLargeScreen = useMediaQuery("(min-width:1024px)");
  const isMediumScreen = useMediaQuery(
    "(max-width:1023px) and (min-width:600px)"
  );

  let slidesPerView = 3; // Default for large screens
  if (isLargeScreen) {
    slidesPerView = 3;
  } else if (isMediumScreen) {
    slidesPerView = 2;
  } else {
    slidesPerView = 1;
  }

  return (
    <div>
      <section id="about" className="about">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div
              className="col-lg-6 order-1 order-lg-2"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <img src={about} className="img-fluid" alt="image" />
            </div>
            <div
              className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <h3>We are RA Abrasive Lapping solutions.</h3>
              <ul>
                <li>
                  <i className="bi bi-check2-all"></i>A company focusing on all
                  your precision finishing needs.
                </li>
                <li>
                  <i className="bi bi-check2-all"></i> Almost 15 years of
                  on-hand experience in super finishing areas.
                </li>
                <li>
                  <i className="bi bi-check2-all"></i> Areas like single side
                  lapping, double side lapping, ID/OD lapping, Polishing, etc.
                </li>
              </ul>
              <p>
                We provide different types of products and services like
                consumables, spares, machines, training, and maintenance for
                your lapping and Polishing applications.
              </p>
            </div>
          </div>
          <div style={{ marginTop: "2rem" }}>
            <Swiper
              slidesPerView={slidesPerView}
              spaceBetween={30}
              // mousewheel={true}
              autoplay={true}
              pagination={{
                clickable: true,
              }}
              scrollbar={{ draggable: true }}
              modules={[Pagination, Mousewheel, Autoplay]}
              className="mySwiper"
            >
              {Portfolios.map((Portfolio, index) => (
                <SwiperSlide key={index}>
                  <div className="swiper-card-wrapper">
                    <Card
                      className="swiper-card"
                      style={{ borderRadius: "10px" }}
                    >
                      <img src={Portfolio.image} className="img-fluid" alt="" />
                      <CardContent></CardContent>
                    </Card>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>

      <section id="clients" className="clients">
        <div className="container" data-aos="zoom-in">
          <div className="section-title">
            <h2>Customers</h2>
            <p>Check our Customers</p>
          </div>
          <Swiper
            slidesPerView={slidesPerView}
            spaceBetween={30}
            // mousewheel={true}
            autoplay={true}
            pagination={{
              clickable: true,
            }}
            scrollbar={{ draggable: true }}
            modules={[Pagination, Mousewheel, Autoplay]}
            className="mySwiper"
          >
            {Clients.map((client, index) => (
              <SwiperSlide key={index}>
                <div className="swiper-card-wrapper">
                  <Card className="swiper-card">
                    <img src={client.image} className="img-fluid" alt="" />
                    <CardContent>
                      <div className="customer-info">
                        <div className="customer-links">
                          <a
                            onClick={() => window.open(client.link)}
                            title="More Details"
                          >
                            {" "}
                            <span
                              style={{ fontSize: "20px", marginBottom: "20px" }}
                            >
                              Explore
                            </span>{" "}
                            <i
                              style={{ marginTop: "10px" }}
                              className="bi bi-box-arrow-up-right"
                            ></i>
                          </a>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </div>
  );
}

export default About;
