export const Products = [
    {
      id:"1",
      title: "Lapping Plate",
      information:{
        Category:"Lapping Plates",
        size:"200mm X 200mm",
        serration:"2mm X 2mm",
        material:"Cast Iron",
      },
      description:"This is the most common material for lapping of almost all materials except gallium arsenide semiconductor compound. Use the standard radial serrated plate whenever possible. The only times a solid surface plate should be used is when small components can catch or fall into the serrations and when the plate is being used in a hard diamond polishing process. Serrations can cause component scratching in precision hard (no polishing pad) diamond polishing techniques.",
    image:require("../assets/products/product-1.jpg"),
      images:[
        {
          image:require("../assets/products/product-1.jpg"),
        },
      ]   
    },
    {
      id:"2",
      title: "Lapping Plate",
      information:{
        Category:"Lapping Plates",
        size:"200mm X 200mm",
        serration:"2mm X 2mm",
        material:"Cast Iron",
      },
      description:
        "This is the most common material for lapping of almost all materials except gallium arsenide semiconductor compound. Use the standard radial serrated plate whenever possible. The only times a solid surface plate should be used is when small components can catch or fall into the serrations and when the plate is being used in a hard diamond polishing process. Serrations can cause component scratching in precision hard (no polishing pad) diamond polishing techniques.",
        image:require("../assets/products/product-3.jpg"),
     images:[
        {
            image:require("../assets/products/product-3.jpg"),
        },
      ]  
    },
    {
      id:"3",
      title: "Lapping Plate",
      information:{
        Category:"Lapping Plates",
        size:"200mm X 200mm",
        serration:"2mm X 2mm",
        material:"Cast Iron",
      },
      description: "This is the most common material for lapping of almost all materials except gallium arsenide semiconductor compound. Use the standard radial serrated plate whenever possible. The only times a solid surface plate should be used is when small components can catch or fall into the serrations and when the plate is being used in a hard diamond polishing process. Serrations can cause component scratching in precision hard (no polishing pad) diamond polishing techniques.",
      image:require("../assets/products/product-4.jpg"),
      images:[
        {
            image:require("../assets/products/product-4.jpg"),
        },
      ]  
    },
    {
      id:"4",
      title: "Lapping Plate",
      information:{
        Category:"Lapping Plates",
        size:"200mm X 200mm",
        serration:"2mm X 2mm",
        material:"Cast Iron",
      },
      description: "This is the most common material for lapping of almost all materials except gallium arsenide semiconductor compound. Use the standard radial serrated plate whenever possible. The only times a solid surface plate should be used is when small components can catch or fall into the serrations and when the plate is being used in a hard diamond polishing process. Serrations can cause component scratching in precision hard (no polishing pad) diamond polishing techniques.",
      image:require("../assets/products/product-5.jpg"),
      images:[
        {
            image:require("../assets/products/product-5.jpg"),
        },
      ]  
    },
    {
      id:"5",
      title: "Lapping Plate",
      information:{
        Category:"Lapping Plates",
        size:"200mm X 200mm",
        serration:"2mm X 2mm",
        material:"Cast Iron",
      },
      description: "This is the most common material for lapping of almost all materials except gallium arsenide semiconductor compound. Use the standard radial serrated plate whenever possible. The only times a solid surface plate should be used is when small components can catch or fall into the serrations and when the plate is being used in a hard diamond polishing process. Serrations can cause component scratching in precision hard (no polishing pad) diamond polishing techniques.",
      image:require("../assets/products/product-6.jpg"),
      images:[
        {
          image:require("../assets/products/product-6.jpg"),
        },
      ]  
    },
    {
      id:"6",
      title: "Diamond Liquid",
      information:{
        Category:"Polishing liquid",
        size:"",
        serration:"",
        material:"liquid Diamond",
      },
      description: "We are providing Liquid Diamond Used for - Mining for schools Very small diamond particles are embedded into saw blades, drill bits and grinding wheels to increase their ability to cut tough materials",
      image:require("../assets/products/product-7-2.jpg"),
      images:[
        {
          image:require("../assets/products/product-7-1.jpg")
        },
        {
          image:require("../assets/products/product-7-2.jpg")
        },
        {
          image:require("../assets/products/product-7-3.jpg")
        }
      ]   
    },
    {
      id:"7",
      title: "Flatness Guage",
      information:{
        Category:"Guages",
        size:"",
        serration:"",
        material:"Cast Iron",
      },
      description: "We are providing straight edge guages or flatted edge guages",
      image:require("../assets/products/product-8.jpg"),
      images:[
        {
            image:require("../assets/products/product-8.jpg"),
        },
      ] 
    },

    {
      id:"8",
      title: "Lapping Plate",
      information:{
        Category:"Lapping Plates",
        size:"200mm X 200mm",
        serration:"2mm X 2mm",
        material:"Cast Iron",
      },
      description: "This is the most common material for lapping of almost all materials except gallium arsenide semiconductor compound. Use the standard radial serrated plate whenever possible. The only times a solid surface plate should be used is when small components can catch or fall into the serrations and when the plate is being used in a hard diamond polishing process. Serrations can cause component scratching in precision hard (no polishing pad) diamond polishing techniques.",
      image:require("../assets/products/product-9.jpg"),
      images:[
        {
            image:require("../assets/products/product-9.jpg"),
        },
      ] 
    },
    {
      id:"9",
      title: "Polishing pad",
      information:{
        Category:"Polishing",
        size:"15 inch",
      },
      description: "we are provide the polishing pad used for paint cleaning, remove swirl marks, light scratches, fine sanding scratches, and other defects in your car's paint.",
      image:require("../assets/products/product-10.jpeg"),
      images:[
        {
            image:require("../assets/products/product-10.jpeg"),
        },
        {
            image:require("../assets/products/product-11.jpeg"),
        },
      ] 
    },
    {
      id:"10",
      title: "Straight Edge",
      information:{
        Category:"Straightness",
        size:"2000 mm",
      },
      description: "Straight Edge 200mm, Well designed, durable high precision tools that are suitable for various measuring ranges. This straight edge is the perfect instrument for drawing straight lines or checking for straightness.",
      image:require("../assets/products/product-12.jpeg"),
      images:[
        {
            image:require("../assets/products/product-12.jpeg"),
        },
        {
            image:require("../assets/products/product-13.jpeg"),
        },
      ] 
    },
]
  