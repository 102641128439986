import React, { useEffect, useState } from "react";
import "./serviceDetails.css";
import comingSoon from '../../assets/images/comingSoon.jpeg'
import Carousel from "react-bootstrap/Carousel";
import { useLocation, useNavigate } from "react-router";

function ServiceDetails() {
  const navigate = useNavigate();
  const {state} = useLocation();
  const [pictures, setPictures] = useState([]);
  const [title, setTitle] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    const storedTitle = sessionStorage.getItem("serviceTitle");
    const storedImages = JSON.parse(sessionStorage.getItem("serviceImages"));

    if (state?.service?.title && state?.data?.images) {
      // If data is available in state, update state and sessionStorage
      setTitle(state.service.title);
      setPictures(state.data.images);
      sessionStorage.setItem("serviceTitle", state.service.title);
      sessionStorage.setItem("serviceImages", JSON.stringify(state.data.images));
    } else if (storedTitle && storedImages) {
      // If data is available in sessionStorage, update state
      setTitle(storedTitle);
      setPictures(storedImages);
    }
  }, [state]);


  return (
    <main id="main" className="main">
      <section id="breadcrumbs" className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2 data-aos="fade-right" data-aos-delay="100">
              Service Details
            </h2>
            <ol>
              <li>
                <a
                  onClick={() => navigate("/")}
                  style={{ color: "black" }}
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  Home
                </a>
              </li>
              <li
                style={{ color: "#ffc451", fontWeight: "500" }}
                data-aos="fade-left"
                data-aos-delay="100"
              >
                Service Details
              </li>
              <li
                style={{ color: "#ffc451", fontWeight: "500" }}
                data-aos="fade-left"
                data-aos-delay="100"
              >
               {title}
              </li>
            </ol>
          </div>
        </div>
      </section>

      <section id="portfolio-details" className="portfolio-details">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg" data-aos="zoom-out" data-aos-delay="200">
              {pictures && pictures.length > 0 ? (
                <Carousel>
                  {pictures.map((picture, index) => {
                    return (
                      <Carousel.Item key={index}>
                        <img
                          className="d-block w-100 container-fluid"
                          src={picture.image}
                          alt={picture.name}
                        />
                        <Carousel.Caption>
                          <p style={{ color: "#ffc451" }}>{picture.name}</p>
                        </Carousel.Caption>
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              ) : (
                <div>
                  <img src={comingSoon} alt="coming soon" />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ServiceDetails;
