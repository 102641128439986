import React, { useEffect } from "react";
import "./product.css";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Products } from "../../Data/Products";
import { useNavigate } from "react-router";
import ProductCard from "./ProductCard/ProductCard";

function Product() {
  const data = Products;
  const navigate = useNavigate();

  return (
    <div>
      <section id="product" className="product">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Products</h2>
            <p>Check our Products</p>
          </div>

          <div
            className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 product-container"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {data.map((product) => {
              return (
                <ProductCard
                  images={product.images}
                  img={product.image}
                  app={product.title}
                  className="App"
                  info={product.information}
                  descrip={product.description}
                />
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Product;
